const jobPersonality = {
    ISTJ: "Computer And Information Reserch Scientists, Software Developer, Quality Assurance Analysts, and Testers, Database Administrators and Architects, Computer Network Architects",
    ISTP: "Computer And Information Reserch Scientists, Network and Computer System Administrator",
    ISFJ: "Network and Computer System Administrator",
    ISFP: "Computer Support Specialists, Information Security Analysts",
    INFJ: "Web Developer & Digital Designer, Software Developer, Quality Assurance Analysts, and Testers",
    INFP: "Computer Support Specialists",
    INTJ: "Computer Programmers",
    INTP: "Computer And Information Reserch Scientists",
    ESTJ: "Database Administrators and Architects, Network and Computer System Administrator",
    ESTP: "Computer Support Specialists, Network and Computer System Administrator",
    ESFJ: "Network and Computer System Administrator",
    ESFP: "Computer System Analysts, Computer Support Specialists",
    ENFJ: "Web Developer & Digital Designer, Software Developer, Quality Assurance Analysts, and Testers",
    ENFP: "Web Developer & Digital Designer",
    ENTJ: "Web Developer & Digital Designer, Software Developer, Quality Assurance Analysts, and Testers",
    ENTP: "Computer System Analysts",
};

export default jobPersonality